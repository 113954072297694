// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-toybox-index-tsx": () => import("../src/pages/toybox/index.tsx" /* webpackChunkName: "component---src-pages-toybox-index-tsx" */),
  "component---src-pages-toybox-snake-tsx": () => import("../src/pages/toybox/snake.tsx" /* webpackChunkName: "component---src-pages-toybox-snake-tsx" */)
}

